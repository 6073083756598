import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import qs from 'qs';

import AddIcon from '@mui/icons-material/Add';
import ExportButtonIcon from '@mui/icons-material/CallMade';
import FilterIcon from '@mui/icons-material/FilterAlt';
import { Badge, Box, FormControlLabel, Switch } from '@mui/material';
import {
  DataGridPro,
  GridApiPro,
  GridFilterModel,
  GridOverlay,
  GridPaginationModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { AmountRange } from '@one/api-models/lib/Admin/Common/Filter/AmountRange';
import { DateRange } from '@one/api-models/lib/Admin/Common/Filter/DateRange';
import { OrderListResponse } from '@one/api-models/lib/Admin/Sales/OrderListResponse';

import { ApiError } from 'apiAccess/api-client';
import { RootState } from 'app/store';
import { Checkbox } from 'components/_common/DataGrid/Checkbox';
import { dataGridStyle } from 'components/_common/DataGrid/dataGridStyling';
import { Pagination } from 'components/_common/DataGrid/Pagination';
import { LoadingScreen } from 'components/_common/LoadingScreen';
import { PageHeader } from 'components/_common/PageHeader';
import { useApiHelpers } from 'components/hooks/useApiHelpers';
import { useFiltersToQueryParams } from 'components/hooks/useFiltersToQueryParams';
import { useFormat } from 'components/hooks/useFormat';
import { useToastMessage } from 'components/hooks/useToastMessage';
import OrderColumns from 'components/views/sales/components/OrderColumns';
import { selectActiveBrand, selectActivePartner } from 'slices/applicationDataSlice';
import { Button } from 'styled';
import { FiltersComparisonType, loadFiltersFromQueryParams } from 'utils/salesListFilters';

export interface Filters {
  amountPaid?: AmountRange;
  customerFirstName?: string;
  customerLastName?: string;
  customerEmail?: string;
  createdDate?: DateRange;
  customerKey?: string;
  balance?: AmountRange;
  orderNumber?: string;
  orderStatuses: number[];
  orderFulfillmentStatuses: number[] | string[];
  orderPaymentStatuses: number[];
  supplierOrderReference?: string;
}

const defaultPageSize = 25;

interface OrderDataGridProps {
  selectAllBrandsEnabled: (state: RootState) => boolean;
  selectFiltersModel: (state: RootState) => GridFilterModel;
  setAllBrandsEnabled: (enabled: boolean) => void;
  selectCurrentPage: (state: RootState) => number;
  setCurrentPage: (page: number) => void;
  setFiltersModel: (model: GridFilterModel) => void;
  hideFulfillmentStatusFilter?: boolean;
  defaultOrderFilters?: Filters;
  title: string;
  testId: string;
  label: string;
}

export const OrderDataGrid = ({
  selectAllBrandsEnabled,
  selectFiltersModel,
  selectCurrentPage,
  setCurrentPage,
  setAllBrandsEnabled,
  setFiltersModel,
  hideFulfillmentStatusFilter,
  defaultOrderFilters = { orderStatuses: [], orderFulfillmentStatuses: [], orderPaymentStatuses: [] },
  title,
  testId,
  label,
}: OrderDataGridProps) => {
  const dataGridClasses = dataGridStyle();

  const apiRef = useGridApiRef() as MutableRefObject<GridApiPro>;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { apiErrorHandler } = useToastMessage();
  const { api } = useApiHelpers();

  const navigate = useNavigate();

  const { getEndDate, getStartDate } = useFormat();
  const [searchParams] = useSearchParams();

  const { saveFiltersToQs } = useFiltersToQueryParams();

  const filtersModel = useSelector(selectFiltersModel);
  const allBrandsEnabled = useSelector(selectAllBrandsEnabled);
  const activePartner = useSelector(selectActivePartner);
  const activeBrand = useSelector(selectActiveBrand);
  const page = useSelector(selectCurrentPage);

  const [pageSize, setPageSize] = useState(defaultPageSize);

  const [shouldResetPage, setShouldResetPage] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  // store every filter component interaction in order to update the component UI
  const [componentFilters, setComponentFilters] = useState<GridFilterModel>({ items: [] });
  const isUpdatingFromQs = useRef(false);
  const isUpdatingFromRedux = useRef(false);

  const {
    data: orders,
    isFetching,
    status,
  } = useQuery<OrderListResponse, ApiError>(
    ['orderData', activeBrand, activePartner, page, pageSize, defaultOrderFilters, filtersModel, allBrandsEnabled],
    () =>
      api.order.loadOrderList({
        ...getFilterPayload(
          getFilters(filtersModel, defaultOrderFilters, getStartDate, getEndDate),
          allBrandsEnabled ? [] : [`${activePartner?.key}`],
          allBrandsEnabled ? [] : [`${activeBrand?.key}`],
        ),
        listingCriteria: {
          skip: page * pageSize,
          take: pageSize,
        },
      }),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      keepPreviousData: true,
      onSuccess: () => {
        setIsInitialLoading(false);
        setShouldResetPage(false);
      },
      onError: (error) => {
        setIsInitialLoading(false);
        apiErrorHandler(error);
      },
    },
  );

  useEffect(() => {
    if (isUpdatingFromQs.current) {
      isUpdatingFromQs.current = false;
      return;
    }

    saveFiltersToQs(filtersModel, page, allBrandsEnabled);
    isUpdatingFromRedux.current = true; // Set flag to indicate update from Redux
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, filtersModel, allBrandsEnabled]);

  useEffect(() => {
    if (isUpdatingFromRedux.current) {
      isUpdatingFromRedux.current = false;
      return;
    }

    const parsed = qs.parse(searchParams.toString(), { ignoreQueryPrefix: true });

    const showAllBrands = parsed.allBrands ? true : false;
    const currentPage = parsed.page ? parseInt(parsed.page as string, 10) : 0;
    const urlFilters = loadFiltersFromQueryParams(parsed);

    setAllBrandsEnabled(showAllBrands);
    setCurrentPage(currentPage);

    isUpdatingFromQs.current = true; // Set flag to indicate update from QS
    setFiltersModel(urlFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    setComponentFilters(filtersModel);
  }, [filtersModel]);

  useEffect(() => {
    // onSuccess from useQuery is not being called at the initial render
    if (status === 'success') {
      setIsInitialLoading(false);
    }
  }, [status]);

  const handlePaginationChanges = (data: GridPaginationModel) => {
    setCurrentPage(data.page);
    setPageSize(data.pageSize);
  };

  const handleViewCustomerClick = (params: any) => {
    navigate(`/customers/${params.row.customer.memberKey}`, { state: { refetch: true } });
  };

  const columns = OrderColumns({ handleViewCustomerClick, hideFulfillmentStatusFilter });

  const resetPage = () => {
    setCurrentPage(0);
    setPageSize(defaultPageSize);
    setShouldResetPage(true);
  };

  const handleOrderDetailsClick = (params: any) => {
    const orderNumber = params?.row.orderNumber;
    navigate(`/sales/orders/${orderNumber}`, {
      state: { orderNumber },
    });
  };

  const handleFilterModelChange = (filters: GridFilterModel) => {
    // Handle case when the filters array is empty
    if (!filters?.items?.length) {
      setFiltersModel(filters);
      resetPage();
      return;
    }

    //Prevent API call when a new filter is added and the value is undefined and the amount is empty or the first name and last name are empty
    if (
      filters.items.every(
        (item) =>
          item?.value !== undefined &&
          item?.value.amount !== '' &&
          item?.value.firstName !== '' &&
          item?.value.lastName !== '',
      )
    ) {
      setFiltersModel(filters);
      // Reset the page if it's not the first page
      if (page !== 0) {
        resetPage();
      }
      return;
    }

    setComponentFilters(filters);
  };
  return (
    <Box>
      <LoadingScreen open={isInitialLoading} message={'Loading...'} />
      <PageHeader
        title={title}
        testId={testId}
        titleAside={
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              mt: { xs: 3, sm: 0 },
              flexWrap: { xs: 'wrap', sm: 'nowrap' },
            }}
          >
            <Badge
              badgeContent={filtersModel?.items[0]?.value !== undefined ? 1 : 0}
              color="primary"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Button
                variant="outlined"
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  setAnchorEl(event.currentTarget);
                  apiRef.current.showFilterPanel();
                }}
              >
                <FilterIcon fontSize="small" />
                Filter
              </Button>
            </Badge>
            <Button
              variant="outlined"
              onClick={() =>
                apiRef.current?.exportDataAsCsv({
                  fileName: `Orders_${new Date().toISOString()}`,
                  allColumns: true,
                })
              }
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <ExportButtonIcon fontSize="small" />
                Export
              </Box>
            </Button>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate('/sales/new')}
              data-testid={`CreateSaleOrderButton`}
            >
              Create Sale Order
            </Button>
          </Box>
        }
      />
      <Box>
        <DataGridPro
          apiRef={apiRef}
          paginationMode="server"
          filterMode="server"
          pagination
          filterDebounceMs={3000}
          onPaginationModelChange={handlePaginationChanges}
          onFilterModelChange={handleFilterModelChange}
          onRowClick={(params) => handleOrderDetailsClick(params)}
          rows={orders?.orders?.items || []}
          loading={isFetching || orders == null}
          rowHeight={37}
          columns={columns}
          columnHeaderHeight={36}
          autoHeight
          disableColumnReorder
          disableColumnResize
          disableMultipleColumnsFiltering
          filterModel={componentFilters}
          paginationModel={{
            page: page,
            pageSize: pageSize,
          }}
          getRowId={(row) => row.orderNumber}
          rowCount={orders?.orders.itemCount ?? 0}
          columnVisibilityModel={{
            partner: allBrandsEnabled,
            brand: allBrandsEnabled,
            customerKey: false,
            supplierOrderReference: false,
            customerName: false,
          }}
          slots={{
            loadingOverlay: () => <GridOverlay sx={{ backgroundColor: 'white', opacity: 0.5 }} />,
            pagination: () => (
              <Pagination disablePaginationButtons={orders?.orders?.items.length === 0} resetPage={shouldResetPage} />
            ),
            baseCheckbox: Checkbox,
            toolbar: () => (
              <FormControlLabel
                sx={{ ml: 0 }}
                control={
                  <Switch
                    checked={allBrandsEnabled}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setAllBrandsEnabled(event.target.checked);
                      setShouldResetPage(true);
                    }}
                    inputProps={{
                      // eslint-disable-next-line
                      //@ts-ignore
                      'data-testid': `OrdersAllBrandsSwitchInput`,
                    }}
                  />
                }
                label={label}
              />
            ),
          }}
          slotProps={{
            filterPanel: {
              sx: {
                width: '430px',
              },
              filterFormProps: {
                operatorInputProps: {
                  disabled: true,
                  sx: { display: 'none' },
                },
                valueInputProps: {
                  sx: {
                    width: '220px',
                  },
                },
              },
            },
            panel: {
              anchorEl: anchorEl,
              placement: 'bottom',
            },
          }}
          sx={{
            '& .MuiDataGrid-row': {
              '&:hover': {
                '& .menuOptionButton': {
                  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#FFFFFF',
                  borderRadius: 2,
                },
              },
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              textTransform: 'uppercase',
            },
          }}
          className={dataGridClasses.root}
        />
      </Box>
    </Box>
  );
};

const getFilterPayload = (filters: Filters, partnerKeys: string[], brandKeys: string[]) => {
  const payload: any = {
    partnerKeys: partnerKeys,
    brandKeys: brandKeys,
    orderStatuses: filters.orderStatuses || [],
    orderFulfillmentStatuses: filters.orderFulfillmentStatuses || [],
    orderPaymentStatuses: filters.orderPaymentStatuses || [],
  };
  if (filters.orderNumber) {
    payload.orderNumber = filters.orderNumber;
  }
  if (filters.customerEmail) {
    payload.customerEmail = filters.customerEmail;
  }
  if (filters.customerKey) {
    payload.customerKey = filters.customerKey;
  }
  if (filters.supplierOrderReference) {
    payload.supplierOrderReference = filters.supplierOrderReference;
  }
  if (filters.createdDate) {
    payload.createdDate = {
      startDate: filters.createdDate.startDate,
      endDate: filters.createdDate.endDate,
    };
  }
  if (
    filters.customerFirstName &&
    filters.customerLastName &&
    (filters.customerFirstName.length > 0 || filters.customerLastName.length > 0)
  ) {
    payload.customerFirstName = filters.customerFirstName;
    payload.customerLastName = filters.customerLastName;
  }
  if (filters.amountPaid?.greaterThan?.amount || filters.amountPaid?.lessThan?.amount) {
    payload.amountPaid = filters.amountPaid as AmountRange;
  }
  if (filters.balance?.greaterThan?.amount || filters.balance?.lessThan?.amount) {
    payload.balance = filters.balance as AmountRange;
  }

  return payload;
};

const getFilters = (
  filtersModel: GridFilterModel,
  defaultOrderFilters: Filters,
  getStartDate: (date?: Date) => Date | undefined,
  getEndDate: (date?: Date) => Date | undefined,
) => {
  let filters: Filters = { ...defaultOrderFilters };

  filtersModel.items.forEach((filterItem) => {
    const { field: filterType, value: filterValue } = filterItem;
    if (filterValue !== undefined) {
      switch (filterType) {
        case 'orderStatus':
          filters.orderStatuses = filterValue !== undefined ? [filterValue] : [];
          break;
        case 'orderFulfillmentStatus':
          filters.orderFulfillmentStatuses = filterValue !== undefined ? [filterValue] : [];
          break;
        case 'orderPaymentStatus':
          filters.orderPaymentStatuses = filterValue !== undefined ? [filterValue] : [];
          break;
        case 'orderNumber':
          filters.orderNumber = filterValue as string;
          break;
        case 'customerEmail':
          filters.customerEmail = filterValue as string;
          break;
        case 'customerKey':
          filters.customerKey = filterValue as string;
          break;
        case 'supplierOrderReference':
          filters.supplierOrderReference = filterValue as string;
          break;
        case 'createdDateTime':
          filters.createdDate = {
            startDate: getStartDate(new Date(filterValue.start)),
            endDate: getEndDate(new Date(filterValue.end)),
          };
          break;
        case 'customerName':
          if (
            filterValue.firstName &&
            filterValue.lastName &&
            (filterValue.firstName.length > 0 || filterValue.lastName.length > 0)
          ) {
            filters.customerFirstName = filterValue.firstName as string;
            filters.customerLastName = filterValue.lastName as string;
          }
          break;

        case 'amount':
          if (filterValue.comparison === FiltersComparisonType.greaterThan && filterValue.amount) {
            filters.amountPaid = {
              greaterThan: {
                amount: parseFloat(filterValue.amount),
                currency: 'USD',
                isEstimated: false,
              },
            };
          } else if (filterValue.amount) {
            filters.amountPaid = {
              lessThan: {
                amount: parseFloat(filterValue.amount),
                currency: 'USD',
                isEstimated: false,
              },
            };
          }
          break;
        case 'balance':
          if (filterValue.comparison === FiltersComparisonType.greaterThan && filterValue.amount) {
            filters.balance = {
              greaterThan: {
                amount: parseFloat(filterValue.amount),
                currency: 'USD', //Default value, the BE ignores it
                isEstimated: false,
              },
            };
          } else if (filterValue.amount) {
            filters.balance = {
              lessThan: {
                amount: parseFloat(filterValue.amount),
                currency: 'USD', //Default value, the BE ignores it
                isEstimated: false,
              },
            };
          }
          break;
        default:
          filters = { ...defaultOrderFilters };
      }
    }
  });
  return filters;
};
