import { useNavigate } from 'react-router-dom';

import { DataGridPro, GridFilterModel, GridOverlay, GridPaginationModel } from '@mui/x-data-grid-pro';
import { ListingData } from '@one/api-models/lib/Admin/Common/ListingData';
import { Transaction } from '@one/api-models/lib/Admin/Transaction/Transaction';

import { Checkbox } from 'components/_common/DataGrid/Checkbox';
import { dataGridStyle } from 'components/_common/DataGrid/dataGridStyling';
import { Pagination } from 'components/_common/DataGrid/Pagination';

import TransactionColumns from './TransactionColumns';

interface TransactionsDataGridProps {
  isLoading: boolean;
  transactions?: ListingData<Transaction>;
  allBrandsEnabled: boolean;
  shouldResetPage?: boolean;
  page: number;
  pageSize: number;
  filters?: GridFilterModel;
  gridToolbar?: React.ReactElement;
  handlePaginationChanges: (paginationModel: GridPaginationModel) => void;
  handleFilterModelChange?: (filterModel: GridFilterModel) => void;
}

export const TransactionsDataGrid = ({
  transactions,
  isLoading,
  allBrandsEnabled,
  shouldResetPage,
  handlePaginationChanges,
  handleFilterModelChange,
  page,
  filters,
  pageSize,
  gridToolbar,
}: TransactionsDataGridProps) => {
  const dataGridClasses = dataGridStyle();
  const navigate = useNavigate();

  const handlePaymentDetailsClick = (params: any) => {
    const transactionId = params?.row.transactionId;
    navigate(`/transactions/${transactionId}`, {
      state: { transactionId },
    });
  };

  const handleViewCustomerClick = (params: any) => {
    navigate(`/customers/${params.row.customer.memberKey}`, { state: { refetch: true } });
  };

  const columns = TransactionColumns({ handlePaymentDetailsClick, handleViewCustomerClick });
  return (
    <>
      <DataGridPro
        paginationMode="server"
        filterMode="server"
        pagination
        filterDebounceMs={3000}
        onPaginationModelChange={handlePaginationChanges}
        onFilterModelChange={handleFilterModelChange}
        rows={transactions?.items ?? []}
        rowHeight={37}
        columns={columns}
        loading={isLoading || transactions == null}
        disableColumnReorder
        disableColumnResize
        disableMultipleColumnsFiltering
        columnHeaderHeight={36}
        autoHeight
        onRowClick={(params) => handlePaymentDetailsClick(params)}
        getRowId={(row) => row.transactionId}
        rowCount={transactions?.itemCount ?? 0}
        paginationModel={{
          page: page,
          pageSize: pageSize,
        }}
        filterModel={filters}
        columnVisibilityModel={{
          partner: allBrandsEnabled,
          brand: allBrandsEnabled,
          gatewayReference: false,
          subscriptionGatewayReference: false,
          transactionID: false,
          arn: false,
          orderReference: false,
          customerKey: false,
          customer: false,
        }}
        slots={{
          loadingOverlay: () => <GridOverlay sx={{ backgroundColor: 'white', opacity: 0.5 }} />,
          pagination: () => (
            <Pagination disablePaginationButtons={transactions?.items.length === 0} resetPage={shouldResetPage} />
          ),
          baseCheckbox: Checkbox,
          toolbar: () => gridToolbar ?? null,
        }}
        sx={{
          '& .MuiDataGrid-row': {
            '&:hover': {
              '& .menuOptionButton': {
                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                backgroundColor: '#FFFFFF',
                borderRadius: 2,
              },
            },
          },
        }}
        slotProps={{
          filterPanel: {
            sx: {
              width: '430px',
            },
            filterFormProps: {
              operatorInputProps: {
                disabled: true,
                sx: { display: 'none' },
              },
              valueInputProps: {
                sx: {
                  width: '220px',
                },
              },
            },
          },
        }}
        className={dataGridClasses.root}
      />
    </>
  );
};
