import { lazy, useEffect, useState } from 'react';

import { InteractionStatus } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { Backdrop, CircularProgress } from '@mui/material'; // Assuming you're using Material-UI

const AppAuthenticated = lazy(() => import('./AppAuthenticated'));
const AppUnauthenticated = lazy(() => import('./AppUnauthenticated'));

export function MsalAuthWrapper() {
  const { instance, inProgress } = useMsal();
  const [isRedirectHandled, setIsRedirectHandled] = useState(false);

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isRedirectHandled) {
      instance
        .handleRedirectPromise()
        .then((result) => {
          setIsRedirectHandled(true);
        })
        .catch((error) => {
          console.error('Redirect error:', error);
          setIsRedirectHandled(true);
        });
    }
  }, [instance, inProgress, isRedirectHandled]);

  if (inProgress === InteractionStatus.HandleRedirect || !isRedirectHandled) {
    return (
      <Backdrop open>
        <CircularProgress color="inherit" data-testid="LoadingSpinner" />
      </Backdrop>
    );
  }

  return (
    <>
      <AuthenticatedTemplate>
        <AppAuthenticated />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AppUnauthenticated />
      </UnauthenticatedTemplate>
    </>
  );
}
