import { Fragment } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, useTheme } from '@mui/material';
import { OrderItemSummaryBase } from '@one/api-models/lib/Admin/Sales/OrderItemSummaryBase';
import { PurchaseSummary } from '@one/api-models/lib/Admin/Sales/PurchaseSummary';
import { ReservationItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Accommodation/ReservationItemSummary';
import { AirOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Air/AirOrderItemSummary';
import { CarOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Car/CarOrderItemSummary';
import { CruiseOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Cruise/CruiseOrderItemSummary';
import { ExperienceOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Experience/ExperienceOrderItemSummary';

import { DataDisplay } from 'common';
import { SectionTitle } from 'components/_common/SectionTitle';
import { Dialog, DialogContent, DialogTitle, IconButton } from 'styled';

import { AirOrderItemDetails } from './OrderDetails/AirOrderItemDetails';
import { CarOrderItemDetails } from './OrderDetails/CarOrderItemDetails';
import { CruiseOrderItemDetails } from './OrderDetails/CruiseOrderItemDetails';
import { ExperienceOrderItemDetails } from './OrderDetails/ExperienceOrderItemDetails';
import { ReservationOrderItemDetails } from './OrderDetails/ReservationOrderItemDetails';

interface OrderItemDetailsDialogProps {
  open: boolean;
  testIdPrefix: string;
  orderItem: OrderItemSummaryBase;
  purchaseSummary: PurchaseSummary;
  handleClose: () => void;
}

export const OrderItemDetailsDialog = ({
  open,
  testIdPrefix,
  handleClose,
  orderItem,
  purchaseSummary,
}: OrderItemDetailsDialogProps) => {
  const theme = useTheme();
  let detailsComponent = null;

  switch (orderItem.$type) {
    case CruiseOrderItemSummary.$type: {
      detailsComponent = (
        <CruiseOrderItemDetails
          orderItem={orderItem as CruiseOrderItemSummary}
          purchaseSummary={purchaseSummary as PurchaseSummary}
        />
      );
      break;
    }
    case ReservationItemSummary.$type: {
      const reservationItem = orderItem as ReservationItemSummary;
      detailsComponent = <ReservationOrderItemDetails orderItem={reservationItem} />;
      break;
    }

    case AirOrderItemSummary.$type: {
      detailsComponent = (
        <AirOrderItemDetails
          orderItem={orderItem as AirOrderItemSummary}
          purchaseSummary={purchaseSummary as PurchaseSummary}
        />
      );
      break;
    }
    case ExperienceOrderItemSummary.$type: {
      detailsComponent = <ExperienceOrderItemDetails orderItem={orderItem as ExperienceOrderItemSummary} />;
      break;
    }
    case CarOrderItemSummary.$type: {
      detailsComponent = (
        <CarOrderItemDetails
          orderItem={orderItem as CarOrderItemSummary}
          purchaseSummary={purchaseSummary as PurchaseSummary}
        />
      );
      break;
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      sx={{
        '.MuiDialog-paper': {
          width: '100%',
          maxWidth:
            orderItem.$type && [CruiseOrderItemSummary.$type, AirOrderItemSummary.$type].indexOf(orderItem.$type) > -1
              ? '1100px'
              : '800px',
        },
      }}
    >
      <DialogTitle>
        Order Item
        <IconButton onClick={handleClose} size="small" disableRipple data-testid={`${testIdPrefix}CloseButton`}>
          <CloseIcon sx={{ color: theme.customPalette.icons.light }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {detailsComponent}
        <Divider sx={{ mt: 4, mb: 2 }} />
        <SectionTitle title="Supplier details" variant="h6" />
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} flexWrap="wrap" gap={{ xs: 1, md: 2, lg: 3 }}>
          <DataDisplay label="Supplier / Agency" value={orderItem.supplierIdentifier} />
          <Divider orientation="vertical" flexItem />
          <DataDisplay label="Booking reference" value={orderItem.supplierOrderReference} />
          {orderItem.$type === AirOrderItemSummary.$type &&
            (orderItem as AirOrderItemSummary).recordLocators.length > 0 && (
              <>
                {(orderItem as AirOrderItemSummary).recordLocators.map((recordLocator) => (
                  <Fragment key={`${recordLocator.reference}-${recordLocator.airlineName}`}>
                    <Divider orientation="vertical" flexItem />
                    <DataDisplay
                      label={`Record Locator${recordLocator.airlineName && ` - ${recordLocator.airlineName}`}`}
                      value={recordLocator.reference}
                    />
                  </Fragment>
                ))}
              </>
            )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
