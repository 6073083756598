import { Fragment, useState } from 'react';

import BedroomChildIcon from '@mui/icons-material/BedroomChild';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ExploreIcon from '@mui/icons-material/Explore';
import FlightIcon from '@mui/icons-material/Flight';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import { Box, Typography, useTheme } from '@mui/material';
import { Order } from '@one/api-models/lib/Admin/Sales/Order';
import { OrderItemSummaryBase } from '@one/api-models/lib/Admin/Sales/OrderItemSummaryBase';
import { OrderItemSummary as MerchandiseOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Retail/Merchandise/OrderItemSummary';
import { OrderItemSummary as WineOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Retail/Wine/OrderItemSummary';
import { ReservationItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Accommodation/ReservationItemSummary';
import { AirOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Air/AirOrderItemSummary';
import { CarOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Car/CarOrderItemSummary';
import { CruiseOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Cruise/CruiseOrderItemSummary';
import { ExperienceOrderItemSummary } from '@one/api-models/lib/Admin/Sales/Travel/Experience/ExperienceOrderItemSummary';

import { orderItemStatusOptions } from 'components/_common/constants/StatusOptions';
import { StatusChip } from 'components/_common/StatusChip';
import { useFormat } from 'components/hooks/useFormat';

import { OrderItemDetailsDialog } from './OrderItemDetailsDialog';

interface OrderItemsProps {
  order?: Order;
}

export const OrderItems = ({ order }: OrderItemsProps) => {
  const orderItems = order?.orderItems;
  const theme = useTheme();
  const { formatCurrency: formatCurrencyFunc, formatDate } = useFormat();
  const [selectedOrderItem, setSelectedOrderItem] = useState<OrderItemSummaryBase | undefined>(undefined);
  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };
  const getIcon = (itemType: any) => {
    switch (itemType) {
      case ReservationItemSummary.$type:
        return <BedroomChildIcon fontSize="large" sx={{ color: '#49C788' }} />;
      case CruiseOrderItemSummary.$type:
        return <DirectionsBoatIcon fontSize="large" sx={{ color: '#49C788' }} />;
      case CarOrderItemSummary.$type:
        return <DirectionsCarIcon fontSize="large" sx={{ color: '#49C788' }} />;
      case ExperienceOrderItemSummary.$type:
        return <ExploreIcon fontSize="large" sx={{ color: '#49C788' }} />;
      case AirOrderItemSummary.$type:
        return <FlightIcon style={{ color: '#194073' }} />;
      case MerchandiseOrderItemSummary.$type:
        return <InventoryIcon fontSize="large" sx={{ color: '#49C788' }} />;
      case WineOrderItemSummary.$type:
        return <LocalBarIcon fontSize="large" sx={{ color: '#49C788' }} />;
      default:
        return <InventoryIcon fontSize="large" sx={{ color: '#49C788' }} />;
    }
  };

  const enabledOrderItemDialogTypes = [
    CruiseOrderItemSummary.$type,
    ReservationItemSummary.$type,
    AirOrderItemSummary.$type,
    ExperienceOrderItemSummary.$type,
    CarOrderItemSummary.$type,
  ];

  const hasOrderItemDialog = (itemType: string) => enabledOrderItemDialogTypes.indexOf(itemType) > -1;

  return (
    <>
      <Box borderTop={`1px solid ${theme.palette.divider}`}>
        {orderItems?.map((item: any) => (
          <Box
            key={item.id}
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
              py: 1,
              display: 'flex',
              justifyContent: 'space-between',
              cursor: enabledOrderItemDialogTypes.indexOf(item.$type) > -1 ? 'pointer' : 'default',
            }}
            gap={{ xs: 2, sm: 0 }}
            flexDirection={{ xs: 'column', sm: 'row' }}
            onClick={() => {
              hasOrderItemDialog(item.$type) ? setSelectedOrderItem(item) : setSelectedOrderItem(undefined);
            }}
          >
            <Box display="flex" gap={2} alignItems="center">
              {getIcon(item?.$type)}
              <Box display="flex" flexDirection="column">
                <Box display="flex" gap={1}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 600,
                      color: hasOrderItemDialog(item.$type)
                        ? theme.palette.primary.main
                        : theme.customPalette.text.primary,
                    }}
                  >
                    {item?.productSummary?.name || ''}
                  </Typography>
                  {item?.orderItemStatus && (
                    <StatusChip
                      status={orderItemStatusOptions[item?.orderItemStatus] || item?.orderItemStatus}
                      width="auto"
                    />
                  )}
                </Box>
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', md: 'row' }}
                  flexWrap="wrap"
                  columnGap={{ xs: 0, md: 2 }}
                >
                  <Typography variant="body1" sx={{ color: theme.customPalette.text.label }}>
                    {`Supplier Identifier: ${item?.supplierIdentifier || 'n/a'}`}
                  </Typography>
                  <Typography variant="body1" sx={{ color: theme.customPalette.text.label }}>
                    {`Supplier Ref: ${item?.supplierOrderReference || 'n/a'}`}
                  </Typography>

                  {item.$type === AirOrderItemSummary.$type && (
                    <>
                      {(item as AirOrderItemSummary).recordLocators.map((recordLocator) => (
                        <Fragment key={`${recordLocator.reference}-${recordLocator.airlineName}`}>
                          <Typography variant="body1" sx={{ color: theme.customPalette.text.label }}>
                            {`Record Locator${recordLocator.airlineName && ` - ${recordLocator.airlineName}`}: ${
                              recordLocator.reference || 'n/a'
                            }`}
                          </Typography>
                        </Fragment>
                      ))}
                    </>
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems={{ xs: 'flex-start', sm: 'flex-end', md: 'center' }}
              gap={{ xs: 1, md: 10 }}
            >
              <Typography variant="body1" sx={{ color: theme.customPalette.text.label }}>
                {`Qty:${item?.quantity}`}
              </Typography>
              <Box display="flex" flexDirection="column" alignItems={{ xs: 'flex-start', sm: 'flex-end' }}>
                <Typography variant="subtitle1" sx={{ color: theme.customPalette.text.primary, textAlign: 'right' }}>
                  {`Total: ${formatCurrency(
                    item?.purchaseSummary?.totals?.fiatAmount?.amount,
                    item?.purchaseSummary?.totals?.fiatAmount?.currency,
                  )}`}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      {order && selectedOrderItem && (
        <OrderItemDetailsDialog
          open={!!selectedOrderItem}
          testIdPrefix="OrderItemDetailsDialog"
          orderItem={selectedOrderItem}
          purchaseSummary={order.purchaseSummary}
          handleClose={() => {
            setSelectedOrderItem(undefined);
          }}
        />
      )}
    </>
  );
};
