import { CertificatePayment } from '@one/api-models/lib/Admin/Sales/CertificatePayment';
import { PaymentOptionBase } from '@one/api-models/lib/Admin/Sales/PaymentOptionBase';
import { PurchaseSummary } from '@one/api-models/lib/Admin/Sales/PurchaseSummary';
import { PurchaseSummaryItemType } from '@one/api-models/lib/Admin/Sales/PurchaseSummaryItemType';

export const getCertificateRedemptionPaymentOptions = (
  purchaseSummary: PurchaseSummary | undefined,
): PaymentOptionBase[] => {
  if (purchaseSummary == null) return [];

  const payDuringPurchaseFlow = purchaseSummary.summaryItems.find(
    (i) => i.type === PurchaseSummaryItemType.PayDuringPurchaseFlow,
  );

  const paymentOptions: PaymentOptionBase[] = payDuringPurchaseFlow
    ? payDuringPurchaseFlow.totals.paymentOptions.filter(
        (paymentOption: PaymentOptionBase) => paymentOption.$type === CertificatePayment.$type,
      )
    : [];

  return paymentOptions;
};
