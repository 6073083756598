import { Box, Divider, Typography } from '@mui/material';
import { CertificatePayment } from '@one/api-models/lib/Admin/Sales/CertificatePayment';
import { PaymentOptionBase } from '@one/api-models/lib/Admin/Sales/PaymentOptionBase';
import { PurchaseSummary } from '@one/api-models/lib/Admin/Sales/PurchaseSummary';
import { PurchaseSummaryItem } from '@one/api-models/lib/Admin/Sales/PurchaseSummaryItem';
import { PurchaseSummaryItemType } from '@one/api-models/lib/Admin/Sales/PurchaseSummaryItemType';
import { ProductOptionContent } from '@one/api-models/lib/Admin/Sales/Travel/Experience/ProductOptionContent';

import { SectionTitle } from 'components/_common/SectionTitle';
import { useFormat } from 'components/hooks/useFormat';
import { getCertificateRedemptionPaymentOptions } from 'utils/getCertificateRedemptionPaymentOptions';

import { EarnOptionsDisplay } from './EarnOptionsDisplay';
import { PaymentOptionsDisplay } from './PaymentOptionsDisplay';

interface ExperiencePriceDetailsProps {
  purchaseSummary: PurchaseSummary;
  productOption?: ProductOptionContent;
}

export const ExperiencePriceDetails = ({ purchaseSummary, productOption }: ExperiencePriceDetailsProps) => {
  const { formatCurrency: formatCurrencyFunc } = useFormat();

  const formatCurrency = (amount: number | null | undefined, currency: string | undefined) => {
    return formatCurrencyFunc(amount, currency, 2);
  };

  const summaryItems = purchaseSummary.summaryItems;

  const subtotal = getPurchaseSummaryItem(summaryItems, PurchaseSummaryItemType.Subtotal)?.totals.fiatAmount;
  const subTotalPaymentOptions = getPurchaseSummaryItem(summaryItems, PurchaseSummaryItemType.Subtotal)?.totals
    .paymentOptions;

  const taxes = purchaseSummary.summaryItems.find((i) => i.type === PurchaseSummaryItemType.Taxes)?.components;

  const certificateOptions = getCertificateRedemptionPaymentOptions(purchaseSummary);

  return (
    <>
      <Divider sx={{ mb: 2 }} />
      <SectionTitle title="Price Details" variant="h6" />
      {certificateOptions.length > 0 && subtotal != null ? (
        <>
          {certificateOptions.map((paymentOption: PaymentOptionBase) => {
            const certificatePayment = paymentOption as CertificatePayment;
            return (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }} key={paymentOption.displayName}>
                <Typography>{`Certificate: [${certificatePayment?.serialNumber}] ${certificatePayment?.displayName}`}</Typography>
              </Box>
            );
          })}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography>+</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography>Additional Charge</Typography>
            <Typography>{formatCurrency(subtotal.amount, subtotal.currency)}</Typography>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              {productOption && (
                <Typography maxWidth="70%" sx={{ mr: 2, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {productOption.title}
                </Typography>
              )}
              <Typography>{subtotal != null ? formatCurrency(subtotal?.amount, subtotal?.currency) : '-'}</Typography>
            </Box>
            <PaymentOptionsDisplay options={subTotalPaymentOptions} />
          </Box>
        </>
      )}
      {taxes?.map((t, i) => (
        <Box key={i} sx={{ mb: 0.8 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 0.5 }}>
            <Typography sx={{ pr: 2 }}> {t.name} </Typography>

            <Typography>{formatCurrency(t.value.amount, t.value.currency)}</Typography>
          </Box>
          {t.description != null && (
            <Typography variant="body2" sx={{ mt: -0.5 }}>
              {t.description}
            </Typography>
          )}
        </Box>
      ))}

      {purchaseSummary?.totals?.earn?.length > 0 && (
        <Box>
          <Divider sx={{ mt: 3, mb: 2 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
            <Typography fontWeight={700}>Rewards</Typography>
            <Box>
              <EarnOptionsDisplay options={purchaseSummary?.totals?.earn} />
            </Box>
          </Box>
          <Divider sx={{ mt: 2, mb: 2 }} />
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Typography fontWeight={700} fontSize="1.2rem">
          Total
        </Typography>
        <Box>
          <Typography fontWeight={700} fontSize="1.2rem" textAlign={'right'}>
            {formatCurrency(purchaseSummary.totals.fiatAmount.amount, purchaseSummary.totals.fiatAmount.currency)}
          </Typography>
          <PaymentOptionsDisplay options={purchaseSummary.totals.paymentOptions} />
        </Box>
      </Box>
    </>
  );
};

const getPurchaseSummaryItem = (items: PurchaseSummaryItem[] | undefined, type: PurchaseSummaryItemType) => {
  return items?.find((item) => item.type === type);
};
